<template>
  <div>
    <div class="font-weight-bold d-flex flex-row m-t-10">
      <div :style="{ width: '100%' }">
        <custom-point-table :ref="'customPointTable'" @reSearch="reSearch" />
      </div>
    </div>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import customPointTable from "../../component/gridBox/pointManagement/CustomPointTable.vue";

export default {
  props: [],
  components: {
    customPointTable,
  },
  watch: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    submit(data) {
      this.$refs.customPointTable.setItems(data);
    },
    onSave() {
      this.$refs.customPointTable.onSave();
    },
    onRemove() {
      this.$refs.customPointTable.onRemove();
    },
    reSearch() {
      this.$emit("reSearch");
    },
  },
};
</script>

<style scoped>
</style>
